<!--  -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <h3 class="text-center p-50">{{hasEdit==false?'申请':'编辑'}}直播</h3>
                <div>
                    <el-form :model="liveRoom" :rules="liveRoomRules" ref="liveRoom" label-width="120px">
                        <el-form-item label="直播方式">
                            <!-- 直播工具：0手机直播 1推流设备 -->
                            <el-radio-group v-model="liveRoom.roomTool">
                                <el-radio :label="0">手机直播</el-radio>
                                <el-radio :label="1">推流设备直播</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <!-- 直播分类：0展会直播 1行业直播 2工厂直播 3个人直播 -->
                        <el-form-item label="直播分类">
                            <el-select v-model="liveRoom.roomCategory" placeholder="请选择" style="width: 100%;">
                                <el-option v-for="item in liveTypes" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="私密直播" prop="hasPrivacy">
                            <el-switch v-model="liveRoom.hasPrivacy"></el-switch>
                            <el-input v-if="liveRoom.hasPrivacy" @focus="showPasswordEorro=false" v-model="liveRoom.roomPassword" autocomplete="off" placeholder="设置进入直播间密码" maxlength="20" show-password show-word-limit></el-input>
                            <div v-if="liveRoom.hasPrivacy && showPasswordEorro" class="f12 c-4">请设置密码</div>
                        </el-form-item>
                        <!-- 直播标题 -->
                        <el-form-item label="直播标题" prop="roomTitle">
                            <el-input v-model="liveRoom.roomTitle" autocomplete="off" placeholder="填写直播标题" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                        <!-- 直播封面 -->
                        <el-form-item label="直播封面" prop="roomIcon">
                            <upload-image @uploadImageBack="uploadImageBack" :ratio="[16,9]">
                                <img v-if="liveRoom.roomIcon" :src="$oucy.ossUrl+liveRoom.roomIcon" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                        <!-- 直播时间 -->
                        <el-form-item label="直播时间" prop="date">
                            <el-date-picker format="yyyy-MM-dd HH:mm:ss" v-model="liveRoom.date" type="datetimerange" :picker-options="options" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="直播地点" prop="roomAddrName">
                            <el-input v-model="liveRoom.roomAddrName" type="text" :rows="2" autocomplete="off" placeholder="填写直播地址" @focus="liveRoom.roomAddrLat?'':onAddrSelect(addrPoint.JYDZ)" />
                            <el-button @click="onAddrSelect(addrPoint.JYDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="直播介绍" prop="roomDescriber">
                            <div style="">
                                <quill-editor class='editor' ref="myQuillEditor" v-model="liveRoom.roomDescriber" :options="quillEditorOption" @change="onEditorChange($event)" />
                            </div>
                        </el-form-item>
                        <el-form-item label="直播助理">
                            <el-button @click="openDialogFormVisible">添加</el-button>
                            <div class="boxs">
                                <div class="df box" v-for="(v,i) of roomAssistant" :key="i">
                                    <img :src="$oucy.ossUrl + v.detailAvatar" v-if="v.detailAvatar" class="detailAvatar">
                                    <div class="text">
                                        <div class="f14 line-1">{{v.detailNickname}}</div>
                                        <div class="f12">{{v.userPhoneNumber}}</div>
                                    </div>
                                    <i class="el-icon-error delbox" @click="delRoomAssistant(v,i)"></i>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="观看权限">
                            <el-radio-group v-model="liveRoom.hasAll">
                                <el-radio :label="false">所有人</el-radio>
                                <el-radio :label="true">指定用户组</el-radio>
                            </el-radio-group>
                            <div v-if="liveRoom.hasAll">
                                <span class="btn" :class="{'select':v.select}" @click="v.select=!v.select" v-for="(v,i) of firstIdentity" :key="i">{{v.identityName}}
                                    <img src="@/assets/images/stockCar/corner.png" class="corner">
                                </span>
                            </div>
                        </el-form-item>
                        <!--                         <el-form-item label="观看密码">
                            <el-input v-model="liveRoom.roomPassword" autocomplete="off" placeholder="设置进入直播间密码" maxlength="20" show-word-limit></el-input>
                        </el-form-item> -->
                        <el-form-item label="是否回放">
                            <el-switch v-model="liveRoom.roomVideoEnable">
                            </el-switch>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-if="hasEdit">保存</el-button>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-else>提交</el-button>
                            <el-button @click="$oucy.back()">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
        <input style="display: none" :id="hiddenFileInputId" type="file" name="file" multiple accept="image/jpg, image/jpeg, image/png, image/gif" @change="realEditorUploadImg" />
        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
        <selectUserDialog 
            ref="selectUserDialog" 
            @callBack="callBack" 
            type='checkbox'
            :isSelectedClose="isSelectedClose" />
    </div>
</template>
<script>
import oss from "@/util/oss.js"
import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { ltIdentity, cardCategory, cardEnterprise, liveroom } from "@/service"

const toolbarOptions = [
    ["bold", "italic", ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
    [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{ font: [] }], // 字体种类-----[{ font: [] }]
    [{ align: [] }], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["image"] // 链接、图片、视频-----['link', 'image', 'video']
];

export default {
    name: "EnterpriseApply1111",
    components: {
        addr,
        uploadImage
    },
    data() {
        var validatePass = (rule, value, callback) => {
            console.log(value)
            if (value === '') {
                callback(new Error('请选择直播时间'));
            } else
            if (value && value.length > 0) {
                if (new Date(value[0]).getTime() < new Date().getTime()) {
                    callback(new Error('直播开始时间不能小于当前时间'));
                } else {
                    callback();
                }
            } else {
                callback(new Error('请选择直播时间'));
            }
        };
        return {
            identitys: [],
            sites: [],
            enterpriseEnterStatus: null,

            liveRoomRules: {
                roomTitle: [
                    { required: true, message: '请输入直播标题', trigger: 'blur' },
                ],
                roomIcon: [
                    { required: true, message: '请选择直播封面', trigger: 'blur' },
                ],
                date: [
                    // { required: true, message: '请选择直播时间', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ],
                hasPrivacy: [
                    { required: true, message: '请设置密码', trigger: 'blur' },
                ],
                roomAddrName: [
                    { required: true, message: '请选择直播地点', trigger: 'blur' },
                ],
                roomDescriber: [
                    { required: true, message: '请输入直播介绍', trigger: 'blur' },
                ],

            },

            liveRoom: {
                roomTool: 1,
                roomCategory: 0,
                roomTitle: '',
                roomIcon: null,
                date: [new Date().DateAdd('m', 30), new Date().DateAdd('d', 3)],
                hasAll: false,
                roomAddrName: null,
                roomAddrLat: null,
                roomAddrLon: null,
                roomDescriber: null,
                roomAssistant: null,
                roomAssistantJson: null,
                roomTimeBeginEnd: null,
                roomTimeBeginStart: null,
                roomWatchPermission: null,
                roomWatchPermissionJson: null,
                roomVideoEnable: true,
                roomPassword: null,
                hasPrivacy: false,
            },
            roomAssistant: [],
            // 直播分类：0展会直播 1行业直播 2工厂直播 3个人直播
            liveTypes: [{
                id: 0,
                name: '展会直播'
            }, {
                id: 1,
                name: '行业直播'
            }, {
                id: 2,
                name: '工厂直播'
            }, {
                id: 3,
                name: '个人直播'
            }],
            quillEditorOption: {
                placeholder: '填写直播简介',
                theme: "snow",
                modules: {
                    toolbar: toolbarOptions,
                    imageDrop: true,
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white"
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"]
                    },
                },
            },

            hiddenFileInputId: "hiddenFileInputId",

            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
            addrSelectVisible: false,
            addrSelectPoint: null,
            addrPoint: {
                JYDZ: 1,
                GCDZ: 2,
                ZTDZ: 3
            },
            enterprise: null,
            enterpriseId: null,
            ltIdentityList: [],
            isShowCityCode: false,
            cardEnterpriseDetail: {},
            cardCategoryList: [],
            hasEdit: false,
            showPasswordEorro: false,
            firstIdentity: [],
            roomWatchPermissionIds: [],
            options: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - (1000 * 60 * 60 * 24)
                }
            },
            isSelectedClose: false,
        }
    },
    mounted() {
        this.id = this.$route.query.id
        if (this.id) {
            this.hasEdit = true
            this.getMyLiveRoom()
        } else {

            this.findFirstIdentity()
        }
        // 富文本上传图片 要URL
        const me = this;
        this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", function(image) {
            return new Promise((resolve, reject) => {
                if (image) {
                    let fileInput = document.getElementById(me.hiddenFileInputId);
                    fileInput.click();
                }
                resolve(image);
            });
        });

    },
    methods: {
        clearValidate(props) {
            this.$refs["liveRoom"].clearValidate(props)
        },
        onEditorChange(e) {
            if (e.html != '') {
                this.clearValidate('roomDescriber')
            }
            console.log(e)
        },
        findFirstIdentity() {
            ltIdentity.findFirstIdentity({ identityIsEnterprise: false }).then(res => {
                if (res) {
                    this.setSelect(res, 0)
                    this.firstIdentity.push(...res)
                }
                ltIdentity.findFirstIdentity({ identityIsEnterprise: true }).then(res => {
                    if (res) {
                        this.setSelect(res, 1)
                        this.firstIdentity.push(...res)
                    }
                })
            })

        },
        setSelect(list, t) {
            for (let v of list) {
                v.identitySource = t
                v.select = this.roomWatchPermissionIds.indexOf(v.id) > -1
            }
        },
        getSelect() {
            let list = []
            for (let v of this.firstIdentity) {
                if (v.select) {
                    // [{identityName:'设计师',id:'1242432423',identitySource:'0 个人/1 企业'},{identityName:'工厂',id:'567565465',identitySource:'0 个人/1 企业'}]
                    list.push({
                        identityName: v.identityName,
                        id: v.id,
                        identitySource: v.identitySource,
                    })
                }
            }
            return list
        },
        gitRoomAssistant() {
            let list = []
            for (let v of this.roomAssistant) {
                // [{userId:'asdfasfaf4545588',detailAvatar:'adasfafafa.png',detailNickname:'张三',userPhoneNumber:'154545878'}]
                list.push({
                    detailAvatar: v.ltUserDetailFull.detailAvatar,
                    userId: v.id,
                    detailNickname: v.ltUserDetailFull.detailNickname,
                })
            }
            return list
        },
        createLiveRoom() {
            liveroom.createLiveRoom(this.liveRoom).then(res => {
                this.$alert('发布成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        keywordFiltr(fn) {
            this.$oucy.keywordFiltr(this.liveRoom).then(res => {
                this[fn]()
            }, err => {})
        },
        // 查详情
        getMyLiveRoom() {
            liveroom.getMyLiveRoom({ id: this.id }).then(res => {
                // 有数据
                if (res) {
                    this.liveRoom = Object.assign(this.liveRoom, res)
                    this.liveRoom.date = [res.roomTimeBeginStart, res.roomTimeBeginEnd]
                    let roomWatchPermission = this.liveRoom.roomWatchPermission ? JSON.parse(this.liveRoom.roomWatchPermission) : []
                    this.roomAssistant = this.liveRoom.roomAssistant ? JSON.parse(this.liveRoom.roomAssistant) : []
                    let roomWatchPermissionIds = []
                    for (let v of roomWatchPermission) {
                        roomWatchPermissionIds.push(v.id)
                    }
                    this.roomWatchPermissionIds = roomWatchPermissionIds
                    this.liveRoom.hasAll = roomWatchPermissionIds.length > 0
                    this.liveRoom.hasPrivacy = this.liveRoom.roomPassword ? true : false
                }
                this.findFirstIdentity()

            })
        },
        // 发布企业名片
        createCardEnterprise() {
            cardEnterprise.createCardEnterprise(this.liveRoom).then(res => {
                this.$alert('发布成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 修改企业名片
        modifyLiveRoom() {
            liveroom.modifyLiveRoom(this.liveRoom).then(res => {
                // 有名片数据
                this.$alert('修改成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 查询名片分类列表
        getCardCategoryList() {
            cardCategory.getCardCategoryList({ categoryType: 1 }).then(res => {
                this.cardCategoryList = res
                if (res && res.length) {
                    this.liveRoom.cardCategory = res[0].id
                }
            })
        },
        uploadImageBack(v) {
            this.liveRoom.roomIcon = v
            console.log(v)
            this.clearValidate('roomIcon')
        },

        submit: function() {
            const me = this;
            console.log(me.liveRoom);
            if (this.liveRoom.hasPrivacy) {
                if (!this.liveRoom.roomPassword) {
                    this.showPasswordEorro = true
                    return
                }
            } else {
                this.liveRoom.roomPassword = null
            }
            me.$refs["liveRoom"].validate((valid) => {
                if (valid) {

                    let list = this.getSelect()
                    let roomAssistant = this.roomAssistant
                    me.liveRoom.roomAssistant = roomAssistant && roomAssistant.length ? JSON.stringify(roomAssistant) : null
                    me.liveRoom.roomAssistantJson = roomAssistant
                    me.liveRoom.roomWatchPermission = me.liveRoom.hasAll && list && list.length ? JSON.stringify(list) : null
                    me.liveRoom.roomWatchPermissionJson = list
                    me.liveRoom.roomTimeBeginStart = new Date(me.liveRoom.date[0]).format('yyyy-MM-dd hh:mm:ss')
                    me.liveRoom.roomTimeBeginEnd = new Date(me.liveRoom.date[1]).format('yyyy-MM-dd hh:mm:ss')
                    if (me.id) {
                        me.liveRoom.id = me.id
                        this.modifyLiveRoom()
                    } else {
                        this.createLiveRoom()
                    }
                }
            });
        },
        // 打开选择用户
        openDialogFormVisible() {
            const len = this.roomAssistant.length || 0;
            if(len >= 10 ){ //10
                return this.$message.error('直播助理（最多支持10人)');
            }
            this.$refs.selectUserDialog.openDialogFormVisible(this.roomAssistant);
            
        },
        // 选择用户 回调
        callBack(list) {
            const len = list ? list.length : 0;
            if(len > 10){ // 10
                return this.$message.error('直播助理（最多支持10人)');
            }
            let roomAssistant = []
            for (let v of list) {
                if (v.ltUserDetailFull) {
                    roomAssistant.push({
                        detailAvatar: v.ltUserDetailFull.detailAvatar,
                        userId: v.id,
                        detailNickname: v.ltUserDetailFull.detailNickname,
                        userPhoneNumber: v.userPhoneNumber,
                    })
                } else {
                    roomAssistant.push(v)
                }
            }
            this.roomAssistant = roomAssistant;
            this.$refs.selectUserDialog.closeDialogFormVisible();
        },
        // 删除用户
        delRoomAssistant(v, i) {
            this.roomAssistant.splice(i, 1)
        },
        onAddrSelect: function(point) {
            this.addrSelectVisible = true;
            this.addrSelectPoint = point;
        },
        addrSelectHandle: function(addr) {
            console.log('addr::', addr)
            const me = this;
            this.addrSelectVisible = false;
            let thisAddr = addr.address + '(' + addr.name + ')';
            if (!!me.addrSelectPoint) {
                if (me.addrSelectPoint === me.addrPoint.JYDZ) {
                    me.liveRoom.roomAddrLat = addr.location.lat
                    me.liveRoom.roomAddrLon = addr.location.lng
                    me.liveRoom.roomAddrName = thisAddr;
                } else if (me.addrSelectPoint === me.addrPoint.GCDZ) {
                    me.liveRoom.addrGC = thisAddr;
                    me.roomAddrNameGcLatitude = addr.location.lat
                    me.roomAddrNameGcLongtitude = addr.location.lng
                } else if (me.addrSelectPoint === me.addrPoint.ZTDZ) {
                    me.liveRoom.addrZT = thisAddr;
                    me.roomAddrNameZtLatitude = addr.location.lat
                    me.roomAddrNameZtLongtitude = addr.location.lng
                }
            }
            this.clearValidate('roomAddrName')
        },
        realEditorUploadImg: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                let files = document.getElementById(me.hiddenFileInputId).files;
                if (files.length > 0) {
                    me.mqUpload(files, 0);
                }
                resolve();
            });
        },
        mqUpload: function(files, index) {
            const me = this;
            let myQuillEditor = me.$refs.myQuillEditor.quill;
            if (files.length > 0 && index < files.length) {
                let Oss=new oss()
                Oss.startUpload(files[index]).then(res => {
                // me.$oucy.uploadRequest(files[index]).then(res => {
                    let rangeIndex = myQuillEditor.getSelection();
                    myQuillEditor.insertEmbed(rangeIndex == null ? 0 : rangeIndex.index, 'image', me.$oucy.ossUrl + res);
                    index += 1;
                    if (index === files.length) {
                        document.getElementById(me.hiddenFileInputId).value = "";
                    } else {
                        me.mqUpload(files, index);
                    }
                }).catch(err => {
                    document.getElementById(me.hiddenFileInputId).value = "";
                })
            }
        },



    }
}
</script>
<style scoped lang="less">
.apply-container {
    width: 1000px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    /*line-height: normal !important;*/
    /*height: 200px;*/
}

/deep/ .ql-container {
    max-height: 300px;
    overflow: auto;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}




.cropper-content .cropper {
    width: auto;
    height: 300px;
}

.detailAvatar {
    width: 42px;
    height: 42px;
}

.boxs {
    display: flex;
    justify-content: space-between;
    margin: 10px -10px 0;
    flex-wrap: wrap;
}

.box {
    width: 39%;
    // flex: 1;
    margin: 10px;
    border: 1px #f1f1f1 solid;
    padding: 10px;
    position: relative;
}

.text {
    line-height: 20px;
    color: #333;
    padding-left: 10px;
}

.delbox {
    font-size: 18px;
    color: rgba(0, 0, 0, .5);
    padding: 4px;
    position: absolute;
    right: -10px;
    top: -10px;
}

.btn {
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    line-height: 1.5;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}

.avatar,
.el-icon-plus,
.avatar-uploader-icon {
    height: 82px;
    line-height: 82px;
}
</style>